import { Fragment } from 'react';
import { useProxy } from 'valtio/utils';
import { adminStore } from '~/pages/admin/components/adminStore';
import { component } from '~/utils/component';
export const Permission = component(props => {
    useProxy(adminStore);
    const hintNeedPermission = (<Fragment>
      需要權限 {props.oneOfRoleTypes.join(' 或 ')} 您的當前權限為{' '}
      {adminStore.permissions.currentRoleType || '無任何權限'}
    </Fragment>);
    if (!adminStore.permissions.currentRoleType)
        return hintNeedPermission;
    const hasPermission = props.oneOfRoleTypes.includes(adminStore.permissions.currentRoleType);
    if (!hasPermission)
        return hintNeedPermission;
    return <Fragment>{props.children}</Fragment>;
});
