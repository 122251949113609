import { css } from '@emotion/react';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import { useProxy } from 'valtio/utils';
import { apirc } from '~/configs/apirc';
import { adminStore } from '~/pages/admin/components/adminStore';
import { component } from '~/utils/component';
export const UserListHeader = component(props => {
    useProxy(adminStore);
    const getArrow = (key) => {
        if (key === adminStore.sortBy) {
            return (<span css={css `
            color: red;
            font-size: 20px;
          `}>
          {adminStore.sortOrder === 'asc' ? <MdArrowDropUp /> : <MdArrowDropDown />}
        </span>);
        }
        else
            return null;
    };
    const handleChangeSortBy = (key) => {
        if (adminStore.sortBy !== key) {
            adminStore['sortBy'] = key;
            adminStore['sortOrder'] = 'desc';
        }
        else {
            adminStore['sortOrder'] = adminStore.sortOrder === 'desc' ? 'asc' : 'desc';
        }
        apirc.admin.getUsers.fetch();
    };
    return (<tr>
      {props.columns?.checkboxOfNotifyPush && (<th css={[
                sortableColumnCss,
                css `
              min-width: 40px;
            `,
            ]}>
          <div css={css `
              margin-left: 4px;
            `}>
            ✔
          </div>
        </th>)}

      {props.columns?.uid && (<th css={sortableColumnCss} onClick={() => handleChangeSortBy('uid')}>
          UID {getArrow('uid')}
        </th>)}

      {props.columns?.code && (<th css={sortableColumnCss} onClick={() => handleChangeSortBy('code')}>
          會員代碼 {getArrow('code')}
        </th>)}

      {props.columns?.name && (<th css={sortableColumnCss} onClick={() => handleChangeSortBy('name')}>
          姓名
          {getArrow('name')}
        </th>)}

      {props.columns?.email && (<th css={sortableColumnCss} onClick={() => handleChangeSortBy('email')}>
          電子信箱 {getArrow('email')}
        </th>)}

      {props.columns?.createdAt && (<th css={sortableColumnCss} onClick={() => handleChangeSortBy('createdAt')}>
          註冊日期{getArrow('createdAt')}
        </th>)}

      {props.columns?.lastLoginAt && (<th css={sortableColumnCss} onClick={() => handleChangeSortBy('lastLoginAt')}>
          最後登入日期{getArrow('lastLoginAt')}
        </th>)}

      {props.columns?.startedAt && (<th css={sortableColumnCss} onClick={() => handleChangeSortBy('startedAt')}>
          開始日期 {getArrow('startedAt')}
        </th>)}

      {props.columns?.expiredAt && (<th css={sortableColumnCss} onClick={() => handleChangeSortBy('expiredAt')}>
          到期日期 {getArrow('expiredAt')}
        </th>)}

      {props.columns?.remainingDays && (<th css={sortableColumnCss} onClick={() => handleChangeSortBy('expiredAt')}>
          剩餘天數 {getArrow('expiredAt')}
        </th>)}

      {props.columns?.longestSubscriptionStatus && <th>訂閱類型</th>}
    </tr>);
});
const headerColumnCss = css `
  min-width: 130px;
  max-width: 240px;
`;
const sortableColumnCss = css `
  cursor: pointer;
  ${headerColumnCss};
`;
