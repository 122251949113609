import React, { memo, useEffect, useMemo, useState } from 'react';
import { Badge, Button, Checkbox, Group, Input, Modal, Radio, Select, Tabs } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import 'dayjs/locale/zh-tw';
import { useSnapshot } from 'valtio';
import { css } from '@emotion/react';
import dayAPI from '~/utils/dayAPI';
import { fontWeight500, fontWeight600 } from '~/css/font';
import { adminStore } from '~/pages/admin/components/adminStore';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { globalGrey } from '~/modules/AppLayout/Colors';
import { EXPERIENCE } from '~/modules/auth/components/FillDetailFormDIalog';
import { apirc } from '~/configs/apirc';
import { isDate, sortBy } from 'lodash';
const userInfoEmpty = {
    name: '',
    birthday: '',
    gender: '',
    mobilePhone: '',
    tradingSeniority: 0,
    newsletterConsent: false,
};
const UserModal = memo(function UserModal() {
    const { admin, modalOpen, readonlyMode, selectedUserId, userScopeProduct, selectedAgent, currentUser, currentUserSubscriptionHistory, } = useSnapshot(adminStore);
    const products = useMemo(() => {
        if (selectedAgent) {
            const agentProducts = admin?.roles.find(role => role.agentName === selectedAgent);
            return agentProducts?.products;
        }
        return [];
    }, [admin?.roles, selectedAgent]);
    const haveNoSubscriptions = !currentUser.subscriptions?.filter(item => item.productName === userScopeProduct).length;
    const currentSubscription = currentUser.subscriptions?.find(s => s.productName === userScopeProduct);
    const haveUserInfo = !!currentUser.userInfo;
    useEffect(() => {
        if (currentUser.userInfo)
            setUserInfoCopy(currentUser.userInfo);
        else
            setUserInfoCopy({ ...userInfoEmpty, userId: currentUser.uid });
        setUserName(currentUser.name || '');
    }, [currentUser.uid, currentUser.userInfo, currentUser]);
    const [userName, setUserName] = useState(currentUser?.name || '');
    const [userInfoCopy, setUserInfoCopy] = useState({
        ...userInfoEmpty,
        userId: currentUser.uid,
    });
    const handleUserInfoChange = (key, value) => {
        const newOne = { ...userInfoCopy, [key]: value };
        setUserInfoCopy(newOne);
    };
    const [nextExpireDate, setNextExpireDate] = useState(currentSubscription?.expiredAt);
    const now = dayAPI();
    const allSubscriptions = sortBy(currentUser.subscriptions, ['productName']).map(s => (<Badge key={s.productId} color={now.diff(s.expiredAt) < 0 ? 'cyan' : 'orange'} css={css `
        margin: 2px;
      `}>
      {s.productName + ' ' + dayAPI(s.expiredAt).format('YYYY/MM/DD')}
    </Badge>));
    const hasNotifyToken = currentUser.notifyTokens?.length >= 1;
    const token = hasNotifyToken ? currentUser.notifyTokens[0].token : '-';
    const tokenLabel = hasNotifyToken ? token : '-';
    const hasBot = currentUser.lineInfos?.length >= 1;
    const lineInfoUid = hasBot ? currentUser.lineInfos[0].lineUid : '-';
    const lineInfoUidLabel = hasBot ? lineInfoUid : '-';
    return (<Modal onClose={() => {
            adminStore.modalOpen = false;
            setNextExpireDate('');
            apirc.admin.getUsers.fetch();
        }} opened={modalOpen} title={'會員資訊'} size='lg'>
      <Tabs defaultValue='basic'>
        <Tabs.List>
          <Tabs.Tab value='basic'>基本資訊</Tabs.Tab>
          <Tabs.Tab value='subscriptions'>訂閱管理</Tabs.Tab>
          {<Tabs.Tab value='userinfo' disabled={selectedAgent !== 'futuresai'}>
              使用者資訊
            </Tabs.Tab>}
        </Tabs.List>

        <Tabs.Panel value='basic' pt='xs'>
          <div css={css `
              ${flex.v.mainCenter};
              gap: 8px;
              p {
                margin: 0;
              }
            `}>
            {currentUser.provider === 'password' ? (<div css={css `
                  height: 40px;
                  ${flex.h.crossCenter}
                `}>
                <p css={keyCss}>姓名</p>
                <Input defaultValue={userName} value={userName} onChange={e => setUserName(e.currentTarget.value)}></Input>
                <Button css={css `
                    margin: 12px;
                  `} onClick={() => {
                apirc.admin.updateUserName.fetch(userName);
            }}>
                  修改姓名
                </Button>
              </div>) : (<_KeyValue keyName='姓名' value={currentUser.name ?? 'N/A'}/>)}

            <_KeyValue keyName='UID' value={currentUser.uid}/>
            <_KeyValue keyName='會員代碼' value={currentUser.code}/>
            <_KeyValue keyName='登入方式' value={currentUser.provider === 'password'
            ? '帳號密碼'
            : currentUser.provider === 'facebook.com'
                ? 'Facebook'
                : currentUser.provider === 'google.com'
                    ? 'Google'
                    : '未知'}/>
            <_KeyValue keyName='電子信箱' value={currentUser.email ?? ''}/>
            <_KeyValue keyName='註冊時間' value={dayAPI(currentUser.createdAt).format('YYYY/MM/DD HH:mm')}/>
            <_KeyValue keyName='Notify Token' value={tokenLabel}/>
            <_KeyValue keyName='Bot UID' value={lineInfoUidLabel}/>
          </div>
        </Tabs.Panel>

        <Tabs.Panel value='subscriptions' pt='xs'>
          <div>
            <div>
              <div css={flex.h.crossCenter}>
                <p css={labelCss}>所有訂閱</p>
              </div>
              {allSubscriptions}
            </div>
            <div css={productNActionButtonsCss}>
              <p css={labelCss}>訂閱設定</p>
              <Select value={products?.find(product => product.name === userScopeProduct)?.name} defaultValue={products?.find(product => product.name === userScopeProduct)?.name} onChange={value => {
            if (value) {
                const selected = products?.find(product => product.name === value);
                if (selected) {
                    adminStore.userScopeProduct = selected?.name;
                    adminStore.readonlyMode = selected.roleType === 'readOnly';
                }
            }
        }} data={products?.map(product => ({
            value: product.name,
            label: product.displayName
                ? product.displayName + ' ' + product.name
                : product.name,
        })) ?? []}/>
              <Button color={haveNoSubscriptions ? 'teal' : ''} disabled={readonlyMode} onClick={() => {
            if (haveNoSubscriptions)
                apirc.admin.createUserSubscription.fetch({
                    expiredAt: dayAPI().add(7, 'day').toISOString(),
                    status: 'premium',
                });
            else
                apirc.admin.updateUserSubscription.fetch({
                    expiredAt: nextExpireDate ?? '',
                    status: 'premium',
                });
        }}>
                {haveNoSubscriptions ? '開通' : '更新'}
              </Button>
              {!haveNoSubscriptions ? (<Button disabled={readonlyMode ||
                (currentSubscription &&
                    dayAPI(currentSubscription.expiredAt).isBefore(dayAPI()))} onClick={() => {
                apirc.admin.updateUserSubscription.fetch({
                    expiredAt: dayAPI().toISOString(),
                    status: 'premium',
                });
            }} color='red'>
                  終止此訂閱商品
                </Button>) : null}
            </div>
            {haveNoSubscriptions ? null : (<div>
                <div css={flex.h.crossCenter}>
                  <p css={labelCss}>訂閱開始於</p>
                  <p>{dayAPI(currentSubscription?.startedAt).format('YYYY/MM/DD HH:mm')}</p>
                </div>
                <div css={flex.h.crossCenter}>
                  <p css={labelCss}>修改結束日期</p>
                  <DatePickerInput disabled={readonlyMode} {...datepickerShareProps} defaultValue={dayAPI(currentSubscription?.expiredAt).toDate()} value={nextExpireDate
                ? dayAPI(nextExpireDate).toDate()
                : dayAPI(currentSubscription?.expiredAt).toDate()} onChange={value => {
                if (isDate(value)) {
                    setNextExpireDate(dayAPI(value).endOf('day').toISOString());
                }
            }}/>
                </div>
              </div>)}
            <div css={css `
                height: 40vh;
                overflow: auto;
              `}>
              {!haveNoSubscriptions ? <p css={labelCss}>訂閱修改紀錄</p> : null}
              {currentUserSubscriptionHistory
            .slice()
            .reverse()
            .map(row => (<div key={row.id} css={css `
                      ${flex.v.default};
                      margin-bottom: 16px;
                      border-bottom: 1px solid ${globalGrey.g300};
                      p {
                        margin: 0;
                      }
                    `}>
                    <p css={css `
                        ${fontWeight600};
                        font-size: 16px;
                      `}>
                      {dayAPI(row.modifiedAt).format('YYYY/MM/DD HH:mm')}
                    </p>
                    <p css={css `
                        font-size: 12px;
                      `}>
                      {row.status ?? 'null'} - 到期日為{' '}
                      {dayAPI(row.expiredAt).format('YYYY/MM/DD HH:mm')}
                    </p>
                  </div>))}
              {haveNoSubscriptions ? (<p>
                  無紀錄，點選<b>開通</b>按鈕
                </p>) : null}
            </div>
          </div>
        </Tabs.Panel>

        <Tabs.Panel value='userinfo' pt='xs'>
          <div css={css `
              ${flex.v.default};
              gap: 8px;
              p {
                margin: 0;
              }
            `}>
            <Button css={css `
                margin-right: auto;
              `} onClick={() => {
            if (haveUserInfo)
                apirc.admin.updateUserInfo.fetch(userInfoCopy);
            else
                apirc.admin.createUserInfo.fetch(userInfoCopy);
        }}>
              更新使用者資訊
            </Button>
            <div css={flex.h.crossCenter}>
              <p css={keyCss}>姓名</p>
              <Input defaultValue={userInfoCopy.name} value={userInfoCopy.name} onChange={e => handleUserInfoChange('name', e.currentTarget.value)}></Input>
            </div>
            <div css={flex.h.crossCenter}>
              <p css={keyCss}>生日</p>
              <DatePickerInput {...datepickerShareProps} valueFormat='YYYY/MM/DD' value={userInfoCopy.birthday ? dayAPI(userInfoCopy.birthday).toDate() : dayAPI().toDate()} onChange={value => {
            if (isDate(value)) {
                handleUserInfoChange('birthday', dayAPI(value).startOf('day').format('YYYY-MM-DD'));
            }
        }}/>
            </div>
            <div css={flex.h.crossCenter}>
              <p css={keyCss}>性別</p>
              <Radio.Group value={userInfoCopy.gender} onChange={value => {
            handleUserInfoChange('gender', value);
        }}>
                <Group>
                  <Radio value='男' label='男'/>
                  <Radio value='女' label='女'/>
                </Group>
              </Radio.Group>
            </div>
            <div css={flex.h.crossCenter}>
              <p css={keyCss}>電話</p>
              <Input defaultValue={userInfoCopy.mobilePhone} value={userInfoCopy.mobilePhone} onChange={e => handleUserInfoChange('mobilePhone', e.currentTarget.value)}/>
            </div>
            <div css={flex.h.crossCenter}>
              <p css={keyCss}>交易經驗</p>
              <Select value={userInfoCopy.tradingSeniority ? userInfoCopy.tradingSeniority.toString() : '0'} onChange={value => {
            value && handleUserInfoChange('tradingSeniority', Number(value));
        }} data={EXPERIENCE.map(exp => ({ value: exp.value.toString(), label: exp.key }))}/>
            </div>
            <div css={flex.h.crossCenter}>
              <p css={keyCss}>訂閱電子報</p>
              <Checkbox checked={userInfoCopy.newsletterConsent} onChange={e => handleUserInfoChange('newsletterConsent', e.currentTarget.checked)}/>
            </div>
          </div>
        </Tabs.Panel>
      </Tabs>
    </Modal>);
});
const _KeyValue = memo(function _KeyValue({ keyName, value, }) {
    return (<div css={css `
        ${flex.h.default};
        height: 40px;
      `}>
      <p css={keyCss}>{keyName}</p>
      <p>{value}</p>
    </div>);
});
const keyCss = css `
  ${fontWeight500};
  width: 120px;
`;
const productNActionButtonsCss = css `
  ${flex.h.crossCenter};
  margin-top: 8px;
`;
const labelCss = css `
  ${fontWeight600};
  font-size: 14px;
  margin: 0;
  margin-right: 16px;
  width: 100px;
  height: 48px;
  line-height: 48px;
`;
const datepickerShareProps = {
    clearable: true,
    valueFormat: 'YYYY/MM/DD HH:mm',
    maw: 200,
    locale: 'zh-tw',
    firstDayOfWeek: 0,
};
export default UserModal;
