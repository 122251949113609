import { css } from '@emotion/react';
import { Checkbox, Tooltip } from '@mantine/core';
import { Fragment } from 'react';
import { useProxy } from 'valtio/utils';
import { globalGrey } from '~/modules/AppLayout/Colors';
import { adminNotifyPushStore } from '~/pages/admin/components/adminStore';
import { useClipboardFn } from '~/pages/admin/hooks/useClipboardFn';
import { component } from '~/utils/component';
import dayAPI from '~/utils/dayAPI';
export const UserListRows = component(props => {
    useProxy(adminNotifyPushStore);
    const clipboard = useClipboardFn();
    return (<Fragment>
      {props.users.map((user, index) => {
            const lastValidSubscription = user.subscriptions?.reduce((prev, current) => prev.expiredAt > current.expiredAt ? prev : current); // 多筆訂閱資料 選取訂閱到期日最遠的
            const expiredDate = dayAPI(lastValidSubscription?.expiredAt);
            const remainingDays = expiredDate.diff(dayAPI(), 'day', true);
            const end = dayAPI(lastValidSubscription?.expiredAt).format('YYYY/MM/DD HH:mm');
            const start = dayAPI(lastValidSubscription?.startedAt).format('YYYY/MM/DD HH:mm');
            const created = dayAPI(user.createdAt).format('YYYY/MM/DD HH:mm');
            const lastLoginAt = dayAPI(user.lastLoginAt).format('YYYY/MM/DD HH:mm');
            const dayjsNow = dayAPI();
            const isSelected = adminNotifyPushStore.selectedUserUids.includes(user.uid);
            const hasNotifyToken = user.notifyTokens.length >= 1;
            return (<tr key={user.uid} css={css `
              :hover {
                background: ${globalGrey.g200};
                cursor: pointer;
              }
            `} onClick={() => {
                    props.onRowClick?.(user);
                }}>
            {props.columns?.checkboxOfNotifyPush && (<td>
                <Checkbox disabled={(adminNotifyPushStore.pushChannel === 'Bot' && !user.lineInfos.length) ||
                        (adminNotifyPushStore.pushChannel === 'Notify' && !hasNotifyToken) ||
                        (adminNotifyPushStore.pushChannel === 'Chatwoot' && !user.chatwootInfos.length)} onChange={e => {
                        if (isSelected) {
                            const newArr = adminNotifyPushStore.selectedUserUids.filter(uid => uid !== user.uid);
                            adminNotifyPushStore.selectedUserUids = newArr;
                        }
                        else {
                            const newArr = [...adminNotifyPushStore.selectedUserUids, user.uid];
                            adminNotifyPushStore.selectedUserUids = newArr;
                        }
                    }} checked={isSelected}/>
              </td>)}

            {props.columns?.uid && (<td css={[
                        clipboard.baseCssset,
                        css `
                    overflow: hidden;
                    text-overflow: ellipsis;
                  `,
                    ]} onClick={() => {
                        clipboard.copy(user.uid);
                    }}>
                <Tooltip label={user.uid}>
                  <p>{user.uid.slice(0, 10)}</p>
                </Tooltip>
              </td>)}

            {props.columns?.code && <td>{user.code}</td>}

            {props.columns?.name && <td>{user.name}</td>}

            {props.columns?.email && <td>{user.email}</td>}

            {props.columns?.createdAt && <td>{created}</td>}

            {props.columns?.lastLoginAt && (<td css={css `
                  color: ${dayjsNow.diff(lastLoginAt, 'day') < 7 ? 'black' : globalGrey.g400};
                `}>
                {lastLoginAt}
              </td>)}

            {props.columns?.startedAt && <td>{start}</td>}

            {props.columns?.expiredAt && <td>{end}</td>}

            {props.columns?.remainingDays && (<td css={css `
                  color: ${remainingDays > 0 ? 'black' : globalGrey.g400};
                `}>
                {(remainingDays > 0 ? '' : '過期') + `${Math.abs(Math.round(remainingDays))}天`}
              </td>)}

            {props.columns?.longestSubscriptionStatus && <td>{lastValidSubscription?.status}</td>}
          </tr>);
        })}
    </Fragment>);
});
