import { component } from '~/utils/component';
import { Table } from '@mantine/core';
import { css } from '@emotion/react';
export const ListOutline = component(props => {
    return (<Table className={props.className} css={css `
        position: relative;
        thead tr th,
        tbody tr td {
          padding: 4px 0 4px 8px;
        }
        thead tr td:first-of-type,
        tbody tr td:first-of-type {
          max-width: 200px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        thead {
          position: sticky;
          background: white;
          top: 0;
        }
      `} cellPadding={'0px'} captionSide='bottom'>
      {props.children}
    </Table>);
});
