/** dev:admin 專用 */
export const adminConfig = {
    /** 使用者表格 垂直欄位 顯示配置 */
    userTableColumns: {
        dashboard: {
            uid: {},
            code: {},
            name: {},
            email: {},
            startedAt: {},
            expiredAt: {},
            remainingDays: {},
            createdAt: {},
            lastLoginAt: {},
            longestSubscriptionStatus: {},
        },
        message: {
            checkboxOfNotifyPush: {},
            uid: {},
            code: {},
            name: {},
            email: {},
            expiredAt: {},
            remainingDays: {},
        },
    },
};
