import { css } from '@emotion/react';
import { useClipboard } from '@mantine/hooks';
import { toast } from 'react-toastify';
export const useClipboardFn = () => {
    const clipboard = useClipboard();
    return {
        ...clipboard,
        copy: (value) => {
            clipboard.copy(value);
            toast(<div>已複製 {String(value)}</div>);
        },
        baseCssset: css `
      cursor: copy;
    `,
    };
};
